var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.model
    ? _c(
        "vx-card",
        { staticClass: "py-4", attrs: { title: "" + _vm.model.name } },
        [
          _c("questionnaire-resume", {
            attrs: {
              id: _vm.id,
              content_data: _vm.contentData,
              averageScore: _vm.model.average_score,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }